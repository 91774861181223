const errorCodes = {
  auth: {
    TOO_MANY_ATTEMPTS: 'auth/too-many-requests',
    INVALID_EMAIL: 'auth/invalid-email',
    USER_NOT_FOUND: 'auth/user-not-found',
    WRONG_PASSWORD: 'auth/wrong-password',
    USER_DISABLED: 'auth/user-disabled',
    WEAK_PASSWORD: 'auth/weak-password'
  }
};

export const errorMessages = {
  GENERAL_ERROR: 'Something went wrong. Please try again later',
  [errorCodes.auth.INVALID_EMAIL]: 'Email is invalid. Please, check the credentials and try again',
  [errorCodes.auth.USER_NOT_FOUND]: 'User was not found. Please, check your credentials and try again',
  [errorCodes.auth.WRONG_PASSWORD]: 'Password is wrong. Please, check your credentials and try again',
  [errorCodes.auth.TOO_MANY_ATTEMPTS]: `Access to this account has been temporarily disabled due to many failed
    login attempts. You can try again later`,
  [errorCodes.auth.USER_DISABLED]: 'Your account has been disabled',
  [errorCodes.auth.WEAK_PASSWORD]: 'Password should be at least 6 characters'
};

export const getMessageByErrorCode = (errorCode) => errorMessages[errorCode] || errorMessages.GENERAL_ERROR;
