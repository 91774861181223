import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useFormState, Controller } from 'react-hook-form';
import Meta from '~/ui/common/Meta';
import useForm from '~/ui/common/Form/useForm';
import { useSessionContext } from '~/logic/application/Session';
import Typography, { TypographyVariant } from '~/ui/common/Typography';
import Input, { InputType } from '~/ui/common/Input';
import Button, { ButtonType, ButtonVariant } from '~/ui/common/Button';
import Link from '~/ui/common/Link';
import { validateEmailField, validatePasswordField, validateConfirmPasswordField } from '../validation';
import styles from '../AuthenticationLayout/authenticationLayout.scss';

const initialValues = {
  email: '',
  password: '',
  confirmPassword: ''
};

const SignUp = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const { handleSubmit, control, watch, trigger } = useForm(initialValues);
  const { isDirty } = useFormState({ control });

  const { isFetching, currentUser, register, signIn } = useSessionContext();

  useEffect(() => {
    isDirty && watch('confirmPassword') && trigger('confirmPassword');
  }, [watch('password')]);

  useEffect(() => {
    isDirty && trigger('password');
  }, [watch('confirmPassword')]);

  const handleSignUp = async ({ email, password }) => (
    await register(email, password) && signIn(email, password)
  );

  return !currentUser && (
    <>
      <Meta title="Sign Up" description="Sign Up" />
      <form
        className={ styles.form }
        onSubmit={ handleSubmit(handleSignUp) }
      >
        <Typography
          variant={ TypographyVariant.H6 }
          className={ styles.title }
        >
          Sign Up
        </Typography>
        <Controller
          control={ control }
          name="email"
          render={ ({ field, fieldState: { error } }) => (
            <Input
              { ...field }
              type={ InputType.TEXT }
              label="Email"
              disabled={ isFetching }
              error={ error?.message }
              ref={ emailRef }
            />
          ) }
          rules={ validateEmailField }
        />
        <Controller
          control={ control }
          name="password"
          render={ ({ field, fieldState: { error } }) => (
            <Input
              { ...field }
              type={ InputType.PASSWORD }
              label="Password"
              disabled={ isFetching }
              error={ error?.message }
            />
          ) }
          rules={ validatePasswordField }
        />
        <Controller
          control={ control }
          name="confirmPassword"
          render={ ({ field, fieldState: { error } }) => (
            <Input
              { ...field }
              type={ InputType.PASSWORD }
              label="Confirm Password"
              disabled={ isFetching }
              error={ error?.message }
            />
          ) }
          rules={ validateConfirmPasswordField(watch('password')) }
        />
        <div>
          <Button
            type={ ButtonType.SUBMIT }
            title="Sign up"
            disabled={ isFetching }
            className={ styles.button }
          />
          <NavLink to="/signin">
            <Button
              type={ ButtonType.BUTTON }
              variant={ ButtonVariant.TEXT }
              title="Cancel"
              className={ styles.button }
            />
          </NavLink>
        </div>
        <Typography
          variant={ TypographyVariant.BODY2 }
          className={ styles.haveTroubles }
        >
          Already have an account? <Link to="/signin">Sign In</Link>
        </Typography>
      </form>
    </>
  );
};

export default SignUp;
