import React from 'react';
import ReactDOM from 'react-dom/client';
import ApplicationLayer from '~/layer/ApplicationLayer';
import ProgressLayer from '~/layer/ProgressLayer';
import NotificationsLayer from '~/layer/NotificationsLayer';
import { Layer } from '~/vo/Layer';
import './index.scss';

ReactDOM.createRoot(document.getElementById(Layer.Application)).render(<ApplicationLayer />);
ReactDOM.createRoot(document.getElementById(Layer.Progress)).render(<ProgressLayer />);
ReactDOM.createRoot(document.getElementById(Layer.Notification)).render(<NotificationsLayer />);
