import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import NotificationItem from '~/ui/common/Notifications/NotificationItem';
import { INotification } from '~/vo/Notification';
import NotificationManager from '~/logic/application/Notification/NotificationManager';
import styles from './notifications.scss';

const Notification = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    NotificationManager.addChangeStateListenerCallback(setNotifications);
    return () => {
      NotificationManager.removeChangeStateListenerCallback(setNotifications);
    };
  }, []);

  return notifications.length ? (
    <div className={ styles.notificationsContainer }>
      <Stack
        sx={ { width: '100%' } }
        spacing={ 2 }
        className={ styles.notificationsStack }
      >
        { notifications.map((notification) => (
          <NotificationItem
            key={ notification.uuid }
            { ...notification }
          />
        )) }
      </Stack>
    </div>
  ) : null;
};

export default Notification;
