import React from 'react';
import ButtonComponent from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { ButtonType } from './ButtonType';
import { ButtonVariant } from './ButtonVariant';

interface IButton {
  title?: string,
  type?: ButtonType,
  variant?: ButtonVariant,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  disabled?: boolean,
  className?: string,
  children?: React.ReactNode
}

const Button = ({
  title,
  type = ButtonType.BUTTON,
  variant = ButtonVariant.CONTAINED,
  onClick,
  disabled,
  className,
  children
}: IButton) => {
  if (variant === ButtonVariant.ICON) {
    return (
      <IconButton
        onClick={ onClick }
        disabled={ disabled }
        className={ className }
      >
        { children }
      </IconButton>
    );
  }

  return (
    <ButtonComponent
      type={ type }
      onClick={ onClick }
      variant={ variant }
      disabled={ disabled }
      className={ className }
    >
      { title }
    </ButtonComponent>
  );
};

export default Button;
