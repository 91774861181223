// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-ui-common-Notifications-NotificationItem-notificationItem_notificationItemWrapper {\n  display: inline-block;\n}\n.src-ui-common-Notifications-NotificationItem-notificationItem_notificationItemWrapper .src-ui-common-Notifications-NotificationItem-notificationItem_notificationItem {\n  display: inline-flex;\n}", "",{"version":3,"sources":["webpack://./src/ui/common/Notifications/NotificationItem/notificationItem.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AACE;EACE,oBAAA;AACJ","sourcesContent":[".notificationItemWrapper {\n  display: inline-block;\n\n  .notificationItem {\n    display: inline-flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationItemWrapper": "src-ui-common-Notifications-NotificationItem-notificationItem_notificationItemWrapper",
	"notificationItem": "src-ui-common-Notifications-NotificationItem-notificationItem_notificationItem"
};
export default ___CSS_LOADER_EXPORT___;
