import React from 'react';
import { Helmet } from 'react-helmet-async';
import config from '~/logic/config';

interface IProps {
  title?: string,
  description?: string
}

const Meta = ({
  title,
  description
}: IProps) => (
  <Helmet>
    <title>{ `${ title ? `${ title } - ` : '' }${ config.applicationName }` }</title>
    <meta
      name="description"
      content={ `${ description ? `${ description }, ` : '' }${ config.applicationName }` }
    />
  </Helmet>
);

export default Meta;
