// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-ui-common-Input-input_input {\n  margin-bottom: 30px !important;\n}\n.src-ui-common-Input-input_input.src-ui-common-Input-input_hasErrors {\n  margin-bottom: 0 !important;\n}\n\n.src-ui-common-Input-input_input ~ .src-ui-common-Input-input_error {\n  height: 18px;\n  margin: 2px 0 10px;\n}", "",{"version":3,"sources":["webpack://./src/ui/common/Input/input.scss"],"names":[],"mappings":"AAEA;EACE,8BAAA;AADF;AAGE;EACE,2BAAA;AADJ;;AAKA;EACE,YAAA;EACA,kBAAA;AAFF","sourcesContent":["@import \"~/ui/common/styles/colors.scss\";\n\n.input {\n  margin-bottom: 30px !important;\n\n  &.hasErrors {\n    margin-bottom: 0 !important;\n  }\n}\n\n.input ~ .error {\n  height: 18px;\n  margin: 2px 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "src-ui-common-Input-input_input",
	"hasErrors": "src-ui-common-Input-input_hasErrors",
	"error": "src-ui-common-Input-input_error"
};
export default ___CSS_LOADER_EXPORT___;
