class LocalStorageRepository {
  static setItem = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  static getItem = (key: string) => {
    const item = localStorage.getItem(key);

    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  };

  static removeItem = (key: string) => {
    localStorage.removeItem(key);
  };

  static clear = () => {
    localStorage.clear();
  };
}

export default LocalStorageRepository;
