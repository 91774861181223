import React from 'react';
import { Link as MaterialLink } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styles from './link.scss';

interface IProps {
  to: string,
  children: React.ReactNode
}

const Link = ({ to, children }: IProps) => (
  <MaterialLink
    to={ to }
    component={ NavLink }
    className={ styles.link }
  >
    { children }
  </MaterialLink>
);

export default Link;
