import { getAuth } from 'firebase/auth';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import config from '~/logic/config';

class ApiProvider {
  private static instance: ApiProvider;

  private readonly firebaseApp: FirebaseApp;

  private constructor() {
    this.firebaseApp = initializeApp(config.api);

    initializeAppCheck(this.firebaseApp, {
      provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true
    });
  }

  public static getInstance = () => {
    if (!ApiProvider.instance) {
      ApiProvider.instance = new ApiProvider();
    }

    return ApiProvider.instance;
  };

  private getAuth = () => getAuth(this.firebaseApp);

  public call = (method: (...argv: any) => any, ...argv: any) => (
    method.bind(this, this.getAuth())(...argv)
  );
}

export default ApiProvider;
