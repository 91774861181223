import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import useForm from '~/ui/common/Form/useForm';
import { useSessionContext } from '~/logic/application/Session';
import Typography, { TypographyVariant } from '~/ui/common/Typography';
import Input, { InputType } from '~/ui/common/Input';
import Button, { ButtonType, ButtonVariant } from '~/ui/common/Button';
import Link from '~/ui/common/Link';
import Meta from '~/ui/common/Meta';
import { validateEmailField } from '../validation';
import validateRequired from '~/logic/infrastructure/validation/validateRequired';
import styles from '../AuthenticationLayout/authenticationLayout.scss';

const initialValues = {
  email: '',
  password: ''
};

const SignIn = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const { handleSubmit, control, reset } = useForm(initialValues);

  const { isFetching, currentUser, signIn } = useSessionContext();

  const handleSignIn = ({ email, password }) => {
    signIn(email, password).then(() => {
      reset(initialValues);
      setTimeout(() => emailRef.current?.focus(), 0);
    });
  };

  return !currentUser && (
    <>
      <Meta title="Sign In" description="Sign In" />
      <form
        className={ styles.form }
        onSubmit={ handleSubmit(handleSignIn) }
      >
        <Typography
          variant={ TypographyVariant.H6 }
          className={ styles.title }
        >
          Sign In
        </Typography>
        <Controller
          control={ control }
          name="email"
          render={ ({ field, fieldState: { error } }) => (
            <Input
              { ...field }
              type={ InputType.TEXT }
              label="Email"
              disabled={ isFetching }
              error={ error?.message }
              ref={ emailRef }
            />
          ) }
          rules={ validateEmailField }
        />
        <Controller
          control={ control }
          name="password"
          render={ ({ field, fieldState: { error } }) => (
            <Input
              { ...field }
              type={ InputType.PASSWORD }
              label="Password"
              disabled={ isFetching }
              error={ error?.message }
            />
          ) }
          rules={ validateRequired }
        />
        <div>
          <Button
            type={ ButtonType.SUBMIT }
            title="Sign in"
            disabled={ isFetching }
            className={ styles.button }
          />
          <NavLink to="/signup">
            <Button
              type={ ButtonType.BUTTON }
              variant={ ButtonVariant.TEXT }
              title="Sign up"
              className={ styles.button }
            />
          </NavLink>
        </div>
        <Typography
          variant={ TypographyVariant.BODY2 }
          className={ styles.haveTroubles }
        >
          Have troubles? <Link to="/reset">Reset the password</Link>
        </Typography>
      </form>
    </>
  );
};

export default SignIn;
