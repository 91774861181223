import React from 'react';
import cn from 'classnames';
import TypographyComponent from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyVariant } from './TypographyVariant';
import styles from './typography.scss';

interface ITypography {
  variant: TypographyVariant,
  children: React.ReactNode,
  className?: string
}

const Typography = ({
  variant = TypographyVariant.H3,
  children,
  className
}: ITypography) => (
  <TypographyComponent
    variant={ variant as Variant }
    gutterBottom
    className={ cn(styles.typography, className) }
  >
    { children }
  </TypographyComponent>
);

export default Typography;
