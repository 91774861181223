import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  User
} from 'firebase/auth';
import ApiProvider from '~/logic/infrastructure/api/ApiProvider';

class SessionRepository {
  static apiProvider: ApiProvider = ApiProvider.getInstance();

  static signIn = (email: string, password: string) => (
    SessionRepository.apiProvider.call(signInWithEmailAndPassword, email, password)
  );

  static register = (email: string, password: string) => (
    SessionRepository.apiProvider.call(createUserWithEmailAndPassword, email, password)
  );

  static signOut = () => (
    SessionRepository.apiProvider.call(signOut)
  );

  static registerAuthStateChangeCallback = (callback: (user: User) => void) => (
    SessionRepository.apiProvider.call(onAuthStateChanged, callback)
  );

  static sendPasswordResetEmail = (email: string) => (
    SessionRepository.apiProvider.call(sendPasswordResetEmail, email)
  );
}

export default SessionRepository;
