import validateRequired from '~/logic/infrastructure/validation/validateRequired';
import validateEmail from '~/logic/infrastructure/validation/validateEmail';
import validatePassword from '~/logic/infrastructure/validation/validatePassword';
import validateIdentity from '~/logic/infrastructure/validation/validateIdentity';

export const validateEmailField = {
  ...validateRequired,
  ...validateEmail
};

export const validatePasswordField = {
  ...validateRequired,
  ...validatePassword
};

export const validateConfirmPasswordField = (identityValue: string) => ({
  ...validateRequired,
  ...validateIdentity(identityValue)
});
