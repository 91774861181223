import React, { ForwardedRef, PropsWithRef } from 'react';
import cn from 'classnames';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { InputType } from './InputType';
import { InputVariant } from './InputVariant';
import styles from './input.scss';

interface IInput {
  type: InputType,
  name: string,
  label: string,
  value: string,
  inputRef: ForwardedRef<any>,
  variant?: InputVariant,
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  disabled?: boolean,
  error?: string
}

const Input = ({
  type = InputType.TEXT,
  name,
  label,
  value,
  inputRef,
  variant = InputVariant.OUTLINED,
  onChange,
  disabled,
  error
}: IInput) => (
  <>
    <TextField
      error={ !!error }
      type={ type }
      id={ name }
      name={ name }
      label={ label }
      value={ value }
      variant={ variant }
      onChange={ onChange }
      fullWidth
      disabled={ disabled }
      className={ cn(styles.input, {
        [styles.hasErrors]: !!error
      }) }
      inputRef={ inputRef }
    />
    { error && (
      <FormHelperText className={ styles.error }>
        { error }
      </FormHelperText>
    ) }
  </>
);

export default React.forwardRef((props: PropsWithRef<any>, ref) => (
  <Input { ...props } inputRef={ ref } />
));
