import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import ProgressManager from '~/logic/application/Progress/ProgressManager';

const Progress = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    ProgressManager.addChangeStateListenerCallback(setIsVisible);
    return () => {
      ProgressManager.removeChangeStateListenerCallback(setIsVisible);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <LinearProgress color="success" />
  );
};

export default Progress;
