// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-ui-common-DarkModeSwitch-darkModeSwitch_darkModeSwitchContainer {\n  padding: 10px 20px;\n}\n\n.src-ui-common-DarkModeSwitch-darkModeSwitch_switchControl > span {\n  font-size: 14px;\n}\nhtml.dark .src-ui-common-DarkModeSwitch-darkModeSwitch_switchControl {\n  color: rgba(255, 255, 255, 0.7019607843);\n}\n\n@media only screen and (max-width: 768px) {\n  .src-ui-common-DarkModeSwitch-darkModeSwitch_mobileHidden {\n    display: none;\n  }\n}", "",{"version":3,"sources":["webpack://./src/ui/common/DarkModeSwitch/darkModeSwitch.scss","webpack://./src/ui/common/styles/colors.scss","webpack://./src/ui/common/styles/mixins/mq.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;AAFF;;AAME;EACE,eAAA;AAHJ;AAME;EACE,wCCZS;ADQb;;AENE;EFcF;IAEI,aAAA;EALF;AACF","sourcesContent":["@import \"~/ui/common/styles/colors.scss\";\n@import \"~/ui/common/styles/mixins/mq.scss\";\n\n.darkModeSwitchContainer {\n  padding: 10px 20px;\n}\n\n.switchControl {\n  > span {\n    font-size: 14px;\n  }\n\n  :global(html.dark) & {\n    color: $dark-color;\n  }\n}\n\n.mobileHidden {\n  @include mqMaxWidth($screenS) {\n    display: none;\n  }\n}\n","$dark-background: #2d2d2d;\n$dark-color: #ffffffb3;\n$dark-input-color-highlighted: #2d2d2d;\n$dark-input-background-highlighted: #faffbd;\n","@import \"~/ui/common/styles/variables.scss\";\n\n@mixin mqMaxWidth($max) {\n  @media only screen and (max-width: $max) {\n    @content;\n  }\n}\n\n@mixin mqMinWidth($min) {\n  @media only screen and (min-width: $min) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"darkModeSwitchContainer": "src-ui-common-DarkModeSwitch-darkModeSwitch_darkModeSwitchContainer",
	"switchControl": "src-ui-common-DarkModeSwitch-darkModeSwitch_switchControl",
	"mobileHidden": "src-ui-common-DarkModeSwitch-darkModeSwitch_mobileHidden"
};
export default ___CSS_LOADER_EXPORT___;
