import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { ThemeMode } from '~/vo/ThemeMode';
import LocalStorageRepository from '~/logic/repository/LocalStorageRepository';

const LOCAL_STORAGE_KEY = 'theme';

const defaultSystemMode = (
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
) ? ThemeMode.DARK : ThemeMode.LIGHT;

const useTheme = () => {
  const [mode, setMode] = useState<PaletteMode>(
    LocalStorageRepository.getItem(LOCAL_STORAGE_KEY) || defaultSystemMode
  );

  useEffect(() => {
    document.getElementsByTagName('html')[0].className = mode;
  }, [mode]);

  const toggleMode = () => {
    const toggledMode = mode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT;
    setMode(toggledMode);
    LocalStorageRepository.setItem(LOCAL_STORAGE_KEY, toggledMode);
  };

  return {
    mode,
    toggleMode
  };
};

type ThemeContextType = ReturnType<typeof useTheme>;

export const ThemeContext = createContext<ThemeContextType>(null);

export const useThemeContext = (): ThemeContextType => {
  const context = useContext<ThemeContextType>(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within the ThemeContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode
}

const ThemeContextProvider = ({ children }: IProps) => {
  const contextValue = useTheme();

  const theme = useMemo(() => createTheme({
    palette: {
      mode: contextValue.mode
    }
  }), [
    contextValue.mode
  ]);

  return (
    <ThemeContext.Provider value={ contextValue }>
      <ThemeProvider theme={ theme }>
        { children }
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
