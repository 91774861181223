import React from 'react';
import ContainerComponent from '@mui/material/Container';

interface IContainer {
  children: React.ReactNode
}

const Container = ({ children }: IContainer) => (
  <ContainerComponent maxWidth="xs">
    { children }
  </ContainerComponent>
);

export default Container;
