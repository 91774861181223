import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '~/ui/common/Container';
import Sidebar from '~/ui/common/Sidebar';
import Button, { ButtonVariant } from '~/ui/common/Button';
import styles from './authenticationLayout.scss';
import DarkModeSwitch from '~/ui/common/DarkModeSwitch';

const AuthenticationLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={ styles.authenticationWrapper }>
      <Button
        onClick={ handleToggleSidebar }
        variant={ ButtonVariant.ICON }
        className={ styles.menuButton }
      >
        <MenuIcon />
      </Button>
      <Sidebar isOpen={ isSidebarOpen } onClose={ handleToggleSidebar }>
        <DarkModeSwitch />
      </Sidebar>
      <Container>
        <Outlet />
      </Container>
    </div>
  );
};

export default AuthenticationLayout;
