export enum TypographyVariant {
  BODY1 = 'body1',
  BODY2 = 'body2',
  BUTTON = 'button',
  CAPTION = 'caption',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  INHERIT = 'inherit',
  OVERLINE = 'overline',
  SUBTITLE1 = 'subtitle1',
  SUBTITLE2 = 'subtitle2',
  STRING = 'string'
}
