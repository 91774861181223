import { useForm as useHookForm, FieldValues, UseFormProps } from 'react-hook-form';

const useForm = (initialValues: FieldValues) => (
  useHookForm({
    shouldFocusError: false,
    defaultValues: initialValues
  } as UseFormProps)
);

export default useForm;
