import { EventEmitter } from 'events';
import { v4 as uuid } from 'uuid';
import { INotification } from '~/vo/Notification';
import { NotificationLevel } from '~/vo/types/NotificationLevel';

class NotificationManager extends EventEmitter {
  public static CHANGE_STATE_EVENT = 'NOTIFICATION_MANAGER_CHANGE_STATE_EVENT';

  public notifications: INotification[] = [];

  private emitStateChangeEvent = () => (
    this.emit(NotificationManager.CHANGE_STATE_EVENT, [...this.notifications])
  );

  private createNotification = (level: NotificationLevel, message: string) => {
    this.notifications.push({ uuid: uuid(), level, message });
    this.emitStateChangeEvent();
  };

  public removeNotification = (uuid: string) => {
    this.notifications = this.notifications.filter(
      (notification) => notification.uuid !== uuid
    );

    this.emitStateChangeEvent();
  };

  public error = (message: string) => {
    this.createNotification(NotificationLevel.ERROR, message);
  };

  public addChangeStateListenerCallback = (callback) => {
    this.addListener(NotificationManager.CHANGE_STATE_EVENT, callback);
  };

  public removeChangeStateListenerCallback = (callback) => {
    this.removeListener(NotificationManager.CHANGE_STATE_EVENT, callback);
  };
}

export default new NotificationManager();
