import React, { useRef, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { NotificationLevel } from '~/vo/types/NotificationLevel';
import NotificationManager from '~/logic/application/Notification/NotificationManager';
import { NOTIFICATION_DELAY } from '../constants';
import styles from './notificationItem.scss';

interface INotificationItem {
  uuid: string,
  level: NotificationLevel,
  message: React.ReactNode | string
}

const NotificationItem = ({ uuid, level, message }: INotificationItem) => {
  const notificationRemoveTimeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
  const alertRef = useRef<HTMLDivElement>(null);

  const handleRemoveNotification = () => {
    NotificationManager.removeNotification(uuid);
  };

  const registerNotificationRemoveDelay = () => {
    notificationRemoveTimeoutRef.current = setTimeout(handleRemoveNotification, NOTIFICATION_DELAY);
  };

  const cancelNotificationRemoveDelay = () => {
    clearTimeout(notificationRemoveTimeoutRef.current);
  };

  useEffect(() => {
    notificationRemoveTimeoutRef.current = setTimeout(handleRemoveNotification, NOTIFICATION_DELAY);
    return handleRemoveNotification;
  }, []);

  useEffect(() => {
    alertRef.current?.addEventListener('mouseenter', cancelNotificationRemoveDelay);
    alertRef.current?.addEventListener('mouseleave', registerNotificationRemoveDelay);

    return () => {
      alertRef.current?.removeEventListener('mouseenter', cancelNotificationRemoveDelay);
      alertRef.current?.removeEventListener('mouseleave', registerNotificationRemoveDelay);
    };
  }, []);

  return (
    <div className={ styles.notificationItemWrapper }>
      <Alert
        severity={ level }
        onClose={ handleRemoveNotification }
        ref={ alertRef }
        className={ styles.notificationItem }
      >
        { message }
      </Alert>
    </div>
  );
};

export default NotificationItem;
