// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html.dark .src-ui-common-Typography-typography_typography {\n  color: rgba(255, 255, 255, 0.7019607843);\n}", "",{"version":3,"sources":["webpack://./src/ui/common/Typography/typography.scss","webpack://./src/ui/common/styles/colors.scss"],"names":[],"mappings":"AAGE;EACE,wCCHS;ADCb","sourcesContent":["@import \"~/ui/common/styles/colors.scss\";\n\n.typography {\n  :global(html.dark) & {\n    color: $dark-color;\n  }\n}\n","$dark-background: #2d2d2d;\n$dark-color: #ffffffb3;\n$dark-input-color-highlighted: #2d2d2d;\n$dark-input-background-highlighted: #faffbd;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typography": "src-ui-common-Typography-typography_typography"
};
export default ___CSS_LOADER_EXPORT___;
