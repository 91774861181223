import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import useForm from '~/ui/common/Form/useForm';
import { useSessionContext } from '~/logic/application/Session';
import Typography, { TypographyVariant } from '~/ui/common/Typography';
import Input, { InputType } from '~/ui/common/Input';
import Button, { ButtonType, ButtonVariant } from '~/ui/common/Button';
import Meta from '~/ui/common/Meta';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { validateEmailField } from '../validation';
import styles from '../AuthenticationLayout/authenticationLayout.scss';

const initialValues = {
  email: ''
};

const ResetPassword = () => {
  const [isSuccessScreen, setIsSuccessScreen] = useState<boolean>(false);
  const { handleSubmit, control, getValues } = useForm(initialValues);

  const { isFetching, currentUser, sendPasswordResetEmail } = useSessionContext();

  const handleResetPassword = async ({ email }) => {
    await sendPasswordResetEmail(email) && setIsSuccessScreen(true);
  };

  return !currentUser && (
    <>
      { isSuccessScreen && (
        <ResetPasswordSuccess email={ getValues('email') } />
      ) }
      { !isSuccessScreen && (
        <>
          <Meta title="Reset Password" description="ResetPassword" />
          <form
            className={ styles.form }
            onSubmit={ handleSubmit(handleResetPassword) }
          >
            <Typography
              variant={ TypographyVariant.H6 }
              className={ styles.title }
            >
              Reset Password
            </Typography>
            <Controller
              control={ control }
              name="email"
              render={ ({ field, fieldState: { error } }) => (
                <Input
                  { ...field }
                  type={ InputType.TEXT }
                  label="Email"
                  disabled={ isFetching }
                  error={ error?.message }
                />
              ) }
              rules={ validateEmailField }
            />
            <div>
              <Button
                type={ ButtonType.SUBMIT }
                title="Reset"
                disabled={ isFetching }
                className={ styles.button }
              />
              <NavLink to="/signin">
                <Button
                  type={ ButtonType.BUTTON }
                  variant={ ButtonVariant.TEXT }
                  title="Cancel"
                  className={ styles.button }
                />
              </NavLink>
            </div>
          </form>
        </>
      ) }
    </>
  );
};

export default ResetPassword;
