import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useSessionContext } from '~/logic/application/Session';
import ProgressManager from '~/logic/application/Progress/ProgressManager';
import { Access } from '~/vo/Access';

const whitelistRoutes = [
  '/signin',
  '/signup',
  '/reset'
];

interface IProps {
  component: React.ElementType
  access: Access
}

const Layout = ({ component: LayoutComponent, access }: IProps) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const { isInitialized: isSessionInitialized, currentUser } = useSessionContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setIsInitialized(isSessionInitialized);

    if (access === Access.Private && isSessionInitialized && !currentUser && !whitelistRoutes.includes(pathname)) {
      navigate('signin');
    }

    if (access === Access.Public && isSessionInitialized && currentUser && whitelistRoutes.includes(pathname)) {
      navigate('');
    }
  }, [isSessionInitialized, currentUser]);

  useEffect(() => {
    ProgressManager.setIsInProgress(!isInitialized);
  }, [isInitialized]);

  if (
    (isInitialized && access === Access.Public) ||
    (isInitialized && access === Access.Private && currentUser)
  ) {
    return (
      <LayoutComponent>
        <Outlet />
      </LayoutComponent>
    );
  }

  return null;
};

export default Layout;
