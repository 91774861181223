import React from 'react';
import { useSessionContext } from '~/logic/application/Session';
import Button from '~/ui/common/Button';

const HomePage = () => {
  const { signOut, currentUser } = useSessionContext();

  return (
    <>
      Home page
      { currentUser.email }
      <Button
        title="Sign out"
        onClick={ signOut }
      />
    </>
  );
};

export default HomePage;
