import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from '~/ui/common/Layout';
import { Access } from '~/vo/Access';
import ApplicationLayout from '~/ui/Application/ApplicationLayout';
import HomePage from '~/ui/Application/HomePage';
import AuthenticationLayout from '~/ui/Authentification/AuthenticationLayout';
import SignIn from '~/ui/Authentification/SignIn';
import SignUp from '~/ui/Authentification/SignUp';
import ResetPassword from '~/ui/Authentification/ResetPassword';
import SessionContext from '~/logic/application/Session';
import ThemeContext from '~/logic/application/Theme/ThemeContext';

const ApplicationLayer = () => (
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={ <Layout component={ ApplicationLayout } access={ Access.Private } /> }
          >
            <Route path="/" element={ <HomePage /> } />
          </Route>
          <Route
            path="/"
            element={ <Layout component={ AuthenticationLayout } access={ Access.Public } /> }
          >
            <Route path="/signin" element={ <SignIn /> } />
            <Route path="/signup" element={ <SignUp /> } />
            <Route path="/reset" element={ <ResetPassword /> } />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

export default () => (
  <ThemeContext>
    <SessionContext>
      <ApplicationLayer />
    </SessionContext>
  </ThemeContext>
);
