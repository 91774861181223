import React from 'react';
import { Outlet } from 'react-router-dom';
import Container from '~/ui/common/Container';
import DarkModeSwitch from '~/ui/common/DarkModeSwitch';
import styles from './applicationLayout.scss';

const ApplicationLayout = () => (
  <div className={ styles.applicationWrapper }>
    <DarkModeSwitch />
    <Container>
      <Outlet />
    </Container>
  </div>
);

export default ApplicationLayout;
