import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useThemeContext } from '~/logic/application/Theme/ThemeContext';
import { ThemeMode } from '~/vo/ThemeMode';
import styles from './darkModeSwitch.scss';

interface IDarkModeSwitch {
  className?: string
}

const DarkModeSwitch = ({ className }: IDarkModeSwitch) => {
  const { mode, toggleMode } = useThemeContext();
  const [isChecked, setIsChecked] = useState<boolean>(mode === ThemeMode.DARK);

  useEffect(() => {
    setIsChecked(mode === ThemeMode.DARK);
  }, [mode]);

  return (
    <FormGroup className={ cn(styles.darkModeSwitchContainer, className) }>
      <FormControlLabel
        control={ <Switch /> }
        label={ (
          <span>{ isChecked ? 'Disable' : 'Enable' } Dark<span className={ styles.mobileHidden }> Mode</span></span>
        ) }
        className={ styles.switchControl }
        checked={ isChecked }
        onChange={ toggleMode }
      />
    </FormGroup>
  );
};

export default DarkModeSwitch;
