import React from 'react';
import { NavLink } from 'react-router-dom';
import Typography, { TypographyVariant } from '~/ui/common/Typography';
import Button, { ButtonType, ButtonVariant } from '~/ui/common/Button';
import styles from './resetPasswordSuccess.scss';

interface IProps {
  email: string
}

const ResetPasswordSuccess = ({ email }: IProps) => (
  <div className={ styles.resetPasswordContainer }>
    <Typography
      variant={ TypographyVariant.BODY2 }
      className={ styles.notification }
    >
      Recovery password instructions were sent to <strong>{ email }</strong>.
      Please, check your email.
    </Typography>
    <NavLink to="/signin">
      <Button
        type={ ButtonType.BUTTON }
        variant={ ButtonVariant.TEXT }
        title="Sign in"
      />
    </NavLink>
  </div>
);

export default ResetPasswordSuccess;
