import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { DrawerAnchor } from '~/vo/DrawerAnchor';

interface IProps {
  anchor?: DrawerAnchor,
  isOpen?: boolean
  onClose?: () => void,
  width?: number,
  children: React.ReactNode
}
const Sidebar = ({
  anchor = DrawerAnchor.Right,
  isOpen = false,
  onClose,
  width = 250,
  children
}: IProps) => {
  const handleCloseSidebar = () => {
    onClose && onClose();
  };

  return (
    <Drawer
      anchor={ anchor }
      open={ isOpen }
      onClose={ handleCloseSidebar }
    >
      <Box sx={ { width: anchor === DrawerAnchor.Top || anchor === DrawerAnchor.Bottom ? 'auto' : width } }>
        { children }
      </Box>
    </Drawer>
  );
};

export default Sidebar;
