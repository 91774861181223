import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from 'firebase/auth';
import { getMessageByErrorCode } from '~/logic/application/Notification/errorMessages';
import NotificationManager from '~/logic/application/Notification/NotificationManager';
import ProgressManager from '~/logic/application/Progress/ProgressManager';
import SessionRepository from '~/logic/repository/SessionRepository';

const useSession = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>(null);

  useEffect(() => {
    ProgressManager.setIsInProgress(!isInitialized || isFetching);
  }, [isInitialized, isFetching]);

  useEffect(() => {
    SessionRepository.registerAuthStateChangeCallback((currentUser) => {
      setCurrentUser(currentUser);
      setIsInitialized(true);
    });
  }, []);

  const signIn = async (email: string, password: string) => {
    setIsFetching(true);

    try {
      await SessionRepository.signIn(email, password);
    } catch (error) {
      NotificationManager.error(getMessageByErrorCode(error?.code));
    } finally {
      setIsFetching(false);
    }
  };

  const register = async (email: string, password: string) => {
    setIsFetching(true);

    try {
      await SessionRepository.register(email, password);
      return true;
    } catch (error) {
      NotificationManager.error(getMessageByErrorCode(error?.code));
      return false;
    } finally {
      setIsFetching(false);
    }
  };

  const signOut = async () => {
    setIsFetching(true);

    try {
      await SessionRepository.signOut();
    } catch (error) {
      NotificationManager.error(getMessageByErrorCode(error?.code));
    } finally {
      setIsFetching(false);
    }
  };

  const sendPasswordResetEmail = async (email: string) => {
    setIsFetching(true);

    try {
      await SessionRepository.sendPasswordResetEmail(email);
      return true;
    } catch (error) {
      NotificationManager.error(getMessageByErrorCode(error?.code));
      return false;
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isInitialized,
    isFetching,
    currentUser,
    signIn,
    register,
    signOut,
    sendPasswordResetEmail
  };
};

type SessionContextType = ReturnType<typeof useSession>;

export const SessionContext = createContext<SessionContextType>(null);

export const useSessionContext = (): SessionContextType => {
  const context = useContext<SessionContextType>(SessionContext);
  if (!context) {
    throw new Error('useSessionContext must be used within the SessionContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode
}

const SessionContextProvider = ({ children }: IProps) => (
  <SessionContext.Provider value={ useSession() }>
    { children }
  </SessionContext.Provider>
);

export default SessionContextProvider;
