import { EventEmitter } from 'events';

class ProgressManager extends EventEmitter {
  public static CHANGE_STATE_EVENT = 'PROGRESS_MANAGER_CHANGE_STATE_EVENT';

  public setIsInProgress = (isInProgress: boolean) => {
    this.emit(ProgressManager.CHANGE_STATE_EVENT, isInProgress);
  };

  public addChangeStateListenerCallback = (callback) => {
    this.addListener(ProgressManager.CHANGE_STATE_EVENT, callback);
  };

  public removeChangeStateListenerCallback = (callback) => {
    this.removeListener(ProgressManager.CHANGE_STATE_EVENT, callback);
  };
}

export default new ProgressManager();
